$color_calendar : #6c8e95;

.calendar-container{
    position:absolute;
    z-index: 1000;
    padding: 3rem 1rem 1rem 1rem;
    border-radius: 3px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3);
    background-color: #FFF;
    font-family: Rajdhani;
    font-weight: 500;
    .calendar-header {
        font-size: 0.9rem;
        .calendar-date{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            padding: 0.5rem;
            text-align: center;
            background-color: $color_calendar;
            color: #FFF;
        }
        .calendar-nav {
            width: 100%;
            display: flex;
            flex-grow : 1;
            padding: 0 .2rem;
        }
        .calendar-month, .calendar-year{
            flex-grow: 1;
            font-size: 0.8rem;
            font-weight: 800;
            color: $color_calendar;
            text-align: center;
            border: solid 1px $color_calendar;
            border-width: 1px 0;
        }
        .calendar-btn {
            position:relative;
            width:25px;
            border: solid 1px $color_calendar;
            border-radius: 3px;
            flex-grow: 0;
            flex-shrink: 0;
            cursor: pointer;
            &:after {
                position:absolute;
                display: block;
                color: $color_calendar;
                content: '\f0da';
                font-weight: 900;
                font-family: "Font Awesome 5 Free";
                width: 100%;
                text-align: center;
                line-height: 1.2rem;
            }
            &.calendar-previous{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                &:after{
                    content : '\f0d9';
                }
            }
            &.calendar-next{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
table.table-calendar {
    font-size: 0.8rem;
    th, td {
        position:relative;
        text-align: center;
        padding: 0.35rem 0.6rem;
        border: solid 1px #FFF;
    }
    th {
    }
    td.day {
        font-size: 0.9em;
        font-weight: 600;
        background-color: rgba($color_calendar, 0.2);
        cursor: pointer;
        &.selected-day:hover {
            background-color: $color_calendar;
        }
        &:hover{
            background-color:  rgba($color_calendar, 0.3);
        }
    }
    td.today:after {
        position:absolute;
        top: -1px;
        right:-5px;
        transform: rotate(45deg);
        display: block;
        content: ' ';
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid  $color_calendar;
    }
    td.selected-day.today:after{border-bottom-color: #000;}
    td.selected-day {
        background-color: $color_calendar;
        font-weight: 700;
        color:#FFF;
    }
    td.day-off {
        background-color: #FFF;
    }
}
